import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationSliceState } from '../../Types';

const initialState: NotificationSliceState = {
    emailSettings: [],
    smsSettings: [],
    pushNotificationSettings: [],
    whatsappSettings: [],
    loading: false,
    error: null,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notificationLoading(state) {
            state.loading = true;
        },
        notificationLoaded(state, action: PayloadAction<NotificationSliceState>) {
            state.emailSettings = action.payload.emailSettings;
            state.smsSettings = action.payload.smsSettings;
            state.pushNotificationSettings = action.payload.pushNotificationSettings;
            state.whatsappSettings = action.payload.whatsappSettings;
            state.loading = false;
            state.error = null;
        },
        notificationError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        updateNotificationSettings(state, action: PayloadAction<Partial<NotificationSliceState>>) {
            state.emailSettings = action.payload.emailSettings || state.emailSettings;
            state.smsSettings = action.payload.smsSettings || state.smsSettings;
            state.pushNotificationSettings = action.payload.pushNotificationSettings || state.pushNotificationSettings;
            state.whatsappSettings = action.payload.whatsappSettings || state.whatsappSettings;
        },
        clearNotificationSettings(state) {
            state.emailSettings = [];
            state.smsSettings = [];
            state.pushNotificationSettings = [];
            state.whatsappSettings = [];
        },
        resetNotificationState(state) {
            return initialState;
        }
    }
});

export const {
    notificationLoading,
    notificationLoaded,
    notificationError,
    updateNotificationSettings,
    clearNotificationSettings,
    resetNotificationState
} = notificationSlice.actions;

export const selectNotificationSettings = (state: { notification: NotificationSliceState }) => state.notification;

export default notificationSlice.reducer;
