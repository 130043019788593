import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key';

const encryptor = createTransform(
    (inboundState, key) => {
      return CryptoJS.AES.encrypt(JSON.stringify(inboundState), secretKey).toString();
    },
    (outboundState, key) => {
      const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
      const decryptedState = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedState);
    },
);

export default encryptor;
