import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientsSliceState } from "../../Types";


const initialState: ClientsSliceState = {
    clients: [],
    loading: false,
    error: null,
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        fetchClientsLoading(state) {
            state.loading = true;
        },
        fetchClientsSuccess(state, action: PayloadAction<string[]>) {
            state.clients = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchClientsError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const {
    fetchClientsLoading,
    fetchClientsSuccess,
    fetchClientsError,
} = clientsSlice.actions;

export const selectClients = (state: { clients: ClientsSliceState }) => state.clients.clients;

export default clientsSlice.reducer;
