import Cookies from 'js-cookie';
import { store } from '../Store/store';
import { logout as reduxLogout } from '../Store/slices/auth.slice';
import { resetStore } from '../Store/resetStore';

export const handleLogout = () => {
    store.dispatch(reduxLogout());

    ['accessToken', 'refreshToken', 'jwt'].forEach(cookie => Cookies.remove(cookie, { path: '/' }));
    store.dispatch({ type: 'reset' });
    resetStore();
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = '/login';
};
