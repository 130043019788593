import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, UserState, UserRole } from "../../Types";

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  jwt: null,
  accessToken: null,
  refreshToken: null,
  mfaRequired: false,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
      state.error = null;
    },
    setUserEmail(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.email = action.payload;
      }
    },
    setUserRole(state, action: PayloadAction<UserRole>) {
      if (state.user) {
        state.user.role = action.payload;
      }
    },
    authSuccess(
      state,
      action: PayloadAction<
        | {
            user: UserState;
            jwt: string;
            accessToken: string;
            refreshToken: string;
            mfaRequired: boolean;
          }
        | any
      >
    ) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.jwt = action.payload.jwt;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.mfaRequired = action.payload.mfaRequired;
      state.loading = false;
      state.error = null;
    },
    authFailure(state, action: PayloadAction<string>) {
      state.isAuthenticated = false;
      state.user = null;
      state.jwt = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.mfaRequired = false;
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.jwt = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.mfaRequired = false;
    },
    resetAuthState(state) {
      return initialState;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("reset", () => initialState);
  },
});

export const {
  authStart,
  authSuccess,
  authFailure,
  logout,
  clearError,
  setUserEmail,
  setUserRole,
  resetAuthState,
} = authSlice.actions;
export default authSlice.reducer;
