import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSliceState, UserRole } from "../../Types";

const initialState: UserSliceState = {
    profile: null,
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLoading(state) {
            state.loading = true;
        },
        userProfileLoaded(state, action: PayloadAction<{ email: string; role: UserRole }>) {
            state.profile = action.payload;
            state.loading = false;
            state.error = null;
        },
        userError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        updateUserEmail(state, action: PayloadAction<string>) {
            if (state.profile) {
                state.profile.email = action.payload;
            } else {
                state.profile = { email: action.payload, role: UserRole.Panelist };
            }
        },
        updateUserRole(state, action: PayloadAction<UserRole>) {
            if (state.profile) {
                state.profile.role = action.payload;
            } else {
                state.profile = { email: '', role: action.payload };
            }
        },
        clearUserProfile(state) {
            state.profile = null;
        },
        resetUserState(state) {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase('reset', () => initialState);
    }
});

export const {
    userLoading,
    userProfileLoaded,
    userError,
    updateUserEmail,
    updateUserRole,
    clearUserProfile,
    resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
