import {
  configureStore,
  combineReducers,
  Reducer,
  AnyAction,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/auth.slice";
import userReducer from "./slices/user.slice";
import profileReducer from "./slices/profile.slice";
import notificationReducer from "./slices/notifications.slice";
import projectsReducer from "./slices/projects.slice";
import clientsReducer from "./slices/clients.slice";
import encryptor from "../Middleware/Encryption/transformer";
import { RootState } from "../Types";
import payoutlimitsReducer from "./slices/payoutlimits.slice";
import dataMarketPlaceReducer from "./slices/data-market-place.slice";
import rewardsReducer from './slices/rewards.slice';
import countryReducer from './slices/country.slice';


const rootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === "reset") {
    state = undefined;
  }
  return combineReducers({
    auth: authReducer,
    user: userReducer,
    profile: profileReducer,
    notification: notificationReducer,
    projects: projectsReducer,
    clients: clientsReducer,
    payoutlimit: payoutlimitsReducer,
    datasets: dataMarketPlaceReducer,
    rewards: rewardsReducer,
    country: countryReducer,
  })(state, action);
};

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "user",
    "profile",
    "notification",
    "projects",
    "clients",
    "payoutlimit",
    "datasets",
    "rewards",
    "country",
  ],
  transforms: [encryptor],
};

const persistedReducer = persistReducer<RootState, AnyAction>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
});


export const persistor = persistStore(store);
