import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  RewardsSliceState } from "../../Types";


const initialState: RewardsSliceState = {
    rewards: [],
    loading: false,
    error: null,
};

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        fetchRewardsLoading(state) {
            state.loading = true;
        },
        fetchRewardsSuccess(state, action: PayloadAction<any>) {
            state.rewards = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchRewardsError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const {
    fetchRewardsLoading,
    fetchRewardsSuccess,
    fetchRewardsError,
} = rewardsSlice.actions;

export const selectRewards = (state: { rewards: RewardsSliceState }) => state.rewards.rewards;

export default rewardsSlice.reducer;
