import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileSliceFinalState, ProfileSliceState } from "../../Types";

const initialState: ProfileSliceFinalState = {
    profile: null,
    loading: false,
    error: null,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileLoading(state) {
            state.loading = true;
        },
        profileLoaded(state, action: PayloadAction<ProfileSliceState>) {
            state.profile = action.payload;
            state.loading = false;
            state.error = null;
        },
        profileError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        updateProfile(state, action: PayloadAction<Partial<ProfileSliceState>>) {
            if (state.profile) {
                state.profile = { ...state.profile, ...action.payload };
            } else {
                state.profile = action.payload as ProfileSliceState;
            }
        },
        clearProfile(state) {
            state.profile = null;
        },
        resetProfileState(state) {
            return initialState;
        }
    }
});

export const {
    profileLoading,
    profileLoaded,
    profileError,
    updateProfile,
    clearProfile,
    resetProfileState
} = profileSlice.actions;

export const selectProfile = (state: { profile: ProfileSliceFinalState }) => state.profile.profile;

export default profileSlice.reducer;
