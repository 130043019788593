import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project, ProjectsSliceState } from "../../Types";

const initialState: ProjectsSliceState = {
  projects: [],
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    fetchProjectsLoading(state) {
      state.loading = true;
    },
    fetchProjectsSuccess(state, action: PayloadAction<Project[]>) {
      state.projects = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchProjectsError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchProjectsLoading,
  fetchProjectsSuccess,
  fetchProjectsError,
} = projectsSlice.actions;

export const selectProjects = (state: { projects: ProjectsSliceState }) =>
  state.projects.projects;

export default projectsSlice.reducer;
