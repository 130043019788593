import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from '../Types';
import LoadingOverlay from "../Components/LoadingOverlay";
import { useLanguage } from "../Hooks/useLanguage";
import { handleLogout } from '../Utils/logout';

const ProtectedRoute: React.FC = () => {
    const location = useLocation();
    const authState = useSelector((state: RootState) => state.auth);
    const { isAuthenticated, jwt, accessToken, refreshToken } = authState;
    const [isLoading, setIsLoading] = useState(true);
    const { language } = useLanguage();

    const checkAuth = () => {
        const cookieAccessToken = Cookies.get("accessToken");
        const cookieRefreshToken = Cookies.get("refreshToken");
        const cookieJwt = Cookies.get("jwt");

        return (
            isAuthenticated &&
            jwt &&
            accessToken &&
            refreshToken &&
            cookieAccessToken &&
            cookieRefreshToken &&
            cookieJwt
        );
    };

    useEffect(() => {
        const authenticate = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsLoading(false);
        };

        authenticate();
    }, []);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (!checkAuth()) {
        handleLogout();
        const loginPath = `/${language}/login`;
        return <Navigate to={loginPath} state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
