import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Dataset {
  id: number;
  name: string;
  description: string;
  category: string;
  country: string;
  price: number;
  city: string;
}

interface DatasetCategory {
  id: string;
  name: string;
}

interface SubscriptionPlan {
  id: number;
  name: string;
  description: string;
  subscriptionType: string;
  price: number;
}

interface Subscriptions {
  id: number;
  name: string;
  price: number;
  subscriptionDate: string;
  isExpired: boolean;
  researcher?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  subscriptionPlan?: {
    id: number;
    name: string;
    price: number;
    description: string;
    datasets: number[];
    selectedDatasets?: number[];
  };
}

interface Subscribe {
  id: number;
}

interface Quotation {
  id: number;
  customDatasetId: number;
  price: number;
  status: string;
  paymentId: number;
}

interface CustomDataset {
  id: number;
  name: string;
  description: string;
  category: string;
  country: string;
  price: number;
  city: string;
  quotationId?: number;
  quotation?: Quotation;
}

export interface CreateQuotation {
  customDataSetId: number;
  price: number;
}
interface DatasetSliceState {
  data: Dataset[];
  categories: string[];
  subscriptionPlans: SubscriptionPlan[];
  subscriptions: Subscriptions[];
  subscribe: any;
  userCustomDatasets: CustomDataset[];
  customDatasets: CustomDataset[];
  createQuotation: CreateQuotation | {};
  loading: boolean;
  error: string | null;
}

const initialState: DatasetSliceState = {
  data: [],
  categories: [],
  subscriptionPlans: [],
  subscriptions: [],
  subscribe: {},
  userCustomDatasets: [],
  customDatasets: [],
  createQuotation: {},
  loading: false,
  error: null,
};

const datasetSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    fetchDatasetsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetsSuccess(state, action: PayloadAction<Dataset[]>) {
      state.data = action.payload;
      state.loading = false;
    },
    fetchDatasetsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchDatasetCategoriesLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDatasetCategoriesSuccess(state, action: PayloadAction<string[]>) {
      state.categories = action.payload;
      state.loading = false;
    },
    fetchDatasetCategoriesError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSubscriptionPlansLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubscriptionPlansSuccess(
      state,
      action: PayloadAction<SubscriptionPlan[]>
    ) {
      state.loading = false;
      state.subscriptionPlans = action.payload;
    },
    fetchSubscriptionPlansError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSubscriptionsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubscriptionsSuccess(state, action: PayloadAction<Subscriptions[]>) {
      state.loading = false;
      state.subscriptions = action.payload;
    },
    fetchSubscriptionsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    subscribeLoading(state) {
      state.loading = true;
      state.error = null;
    },
    subscribeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.subscriptions = action.payload;
    },
    subscribeError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    userCustomDatasetsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    userCustomDatasetsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.userCustomDatasets = action.payload;
    },
    userCustomDatasetsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCustomDatasetsLoading(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCustomDatasetsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.customDatasets = action.payload;
    },
    fetchCustomDatasetsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    createQuotationLoading(state) {
      state.loading = true;
      state.error = null;
    },
    createQuotationSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.createQuotation = action.payload;
    },
    createQuotationError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDatasetsLoading,
  fetchDatasetsSuccess,
  fetchDatasetsError,
  fetchDatasetCategoriesLoading,
  fetchDatasetCategoriesSuccess,
  fetchDatasetCategoriesError,
  fetchSubscriptionPlansLoading,
  fetchSubscriptionPlansSuccess,
  fetchSubscriptionPlansError,
  fetchSubscriptionsLoading,
  fetchSubscriptionsError,
  fetchSubscriptionsSuccess,
  subscribeLoading,
  subscribeError,
  subscribeSuccess,
  userCustomDatasetsLoading,
  userCustomDatasetsError,
  userCustomDatasetsSuccess,
  fetchCustomDatasetsLoading,
  fetchCustomDatasetsSuccess,
  fetchCustomDatasetsError,
  createQuotationLoading,
  createQuotationSuccess,
  createQuotationError,
} = datasetSlice.actions;

export const selectDatasets = (state: { datasets: DatasetSliceState }) =>
  state.datasets.data;
export const selectDatasetCategories = (state: {
  datasets: DatasetSliceState;
}) => state.datasets.categories;
export const selectSubscriptionPlans = (state: {
  datasets: DatasetSliceState;
}) => state.datasets.subscriptionPlans;
export const fetchSubscriptions = (state: { datasets: DatasetSliceState }) =>
  state.datasets.subscriptions;
export const selectSubscriptions = (state: { datasets: DatasetSliceState }) =>
  state.datasets.subscriptions;

export const subscribe = (state: { datasets: DatasetSliceState }) =>
  state.datasets.subscribe;

export const selectUserCustomDatasets = (state: {
  datasets: DatasetSliceState;
}) => state.datasets.userCustomDatasets;

export const selectCustomDatasets = (state: { datasets: DatasetSliceState }) =>
  state.datasets.customDatasets;

export default datasetSlice.reducer;
