import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PayoutLimit {
    id: number;
    countryCode: string;
    payoutMethod: string;
    minTransaction: number;
    maxTransaction: number;
    createdAt: string;
    updatedAt: string;
}

interface PayoutLimitsState {
    payoutLimits: PayoutLimit[];
    loading: boolean;
    error: string | null;
}

const initialState: PayoutLimitsState = {
    payoutLimits: [],
    loading: false,
    error: null,
};

const payoutLimitsSlice = createSlice({
    name: "payoutlimit", // Matches the key in combineReducers
    initialState,
    reducers: {
        fetchPayoutLimitsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchPayoutLimitsSuccess(state, action: PayloadAction<PayoutLimit[]>) {
            state.payoutLimits = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchPayoutLimitsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addPayoutLimit(state, action: PayloadAction<PayoutLimit>) {
            state.payoutLimits.push(action.payload);
        },
        updatePayoutLimit(state, action: PayloadAction<PayoutLimit>) {
            const index = state.payoutLimits.findIndex(limit => limit.id === action.payload.id);
            if (index !== -1) {
                state.payoutLimits[index] = action.payload;
            }
        },
        deletePayoutLimit(state, action: PayloadAction<number>) {
            state.payoutLimits = state.payoutLimits.filter(limit => limit.id !== action.payload);
        },
        clearError(state) {
            state.error = null;
        },
        resetPayoutLimitsState(state) {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase("reset", () => initialState);
    },
});

export const {
    fetchPayoutLimitsStart,
    fetchPayoutLimitsSuccess,
    fetchPayoutLimitsFailure,
    addPayoutLimit,
    updatePayoutLimit,
    deletePayoutLimit,
    clearError,
    resetPayoutLimitsState,
} = payoutLimitsSlice.actions;

export default payoutLimitsSlice.reducer;
