import { store, persistor } from './store';
import {resetNotificationState} from "./slices/notifications.slice";
import {resetProfileState} from "./slices/profile.slice";
import {resetUserState} from "./slices/user.slice";
import {resetAuthState} from "./slices/auth.slice";


export const resetStore = () => {
    store.dispatch(resetNotificationState());
    store.dispatch(resetProfileState());
    store.dispatch(resetUserState());
    store.dispatch(resetAuthState());
    persistor.purge()
        .then(() => {
            store.dispatch({ type: 'reset' });
        })
        .catch(error => console.error('purge failed', error));
};
