import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CountryState {
    countryCode: string | null;
}

const initialState: CountryState = {
    countryCode: null,
};

const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryCode(state, action: PayloadAction<string>) {
            state.countryCode = action.payload;
        },
        clearCountryCode(state) {
            state.countryCode = null;
        },
    },
});

export const { setCountryCode, clearCountryCode } = countrySlice.actions;
export default countrySlice.reducer;
